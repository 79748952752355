// JS shared by /cart and /addresses pages
frappe.provide("ugs_web.cart");

var ugs_web_cart = ugs_web.cart;

const address_module = 'ugs_web.custom_classes.address';
const cart_module = 'ugs_web.custom_methods.cart';

$.extend(ugs_web_cart, {
    update_address: function(address_doc, default_address_type, update_cart=false) {
        const title = (address_doc && address_doc.address_title) || 'New Address'
        const d = new frappe.ui.Dialog({
            title: title,
            fields: [
                {
                    label: 'Addressee Name',
                    fieldname: 'address_title',
                    fieldtype: 'Data',
                    reqd: 1
                },
                {
                    label: 'Address Line 1',
                    fieldname: 'address_line1',
                    fieldtype: 'Data',
                    reqd: 1
                },
                {
                    label: 'Address Line 2',
                    fieldname: 'address_line2',
                    fieldtype: 'Data'
                },
                {
                    label: 'City/Town',
                    fieldname: 'city',
                    fieldtype: 'Data',
                    reqd: 1
                },
                {
                    label: 'State',
                    fieldname: 'state',
                    fieldtype: 'Data'
                },
                {
                    label: 'Country',
                    fieldname: 'country',
                    fieldtype: 'Link',
                    options: 'Country',
                    only_select: true,
                    reqd: 1
                },
                {
                    fieldname: "column_break0",
                    fieldtype: "Column Break",
                    width: "50%"
                },
                {
                    label: 'Address Type',
                    fieldname: 'address_type',
                    fieldtype: 'Select',
                    options: [
                        'Shipping',
                        'Billing'
                    ],
                    reqd: 1,
                    default: default_address_type || 'Shipping',
                    read_only: !!(address_doc || default_address_type)
                },
                {
                    label: 'Postal Code (mandatory for UK)',
                    fieldname: 'pincode',
                    fieldtype: 'Data'
                },
                {
                    fieldname: "phone",
                    fieldtype: "Data",
                    label: "Phone",
                    reqd: 1
                },
            ],
            primary_action_label: 'Save',
            primary_action: (values) => {
                let method;
                let args;
                // Validate UK postcode
                if (values.country === 'United Kingdom' && !values.pincode) {
                    frappe.msgprint({
                        title: 'Missing postcode',
                        message: 'Postcode is mandatory for UK addresses.'
                    });
                    return;
                }
                // Validate phone number (allow 0-9, -, +, space and brackets)
                if (values.phone && /[^-+() 0-9]/.test(values.phone)) {
                    frappe.msgprint({
                        title: 'Invalid phone number',
                        message: 'Phone number contains invalid characters.'
                    });
                    return;
                }
                if (address_doc) {
                    // Update with values
                    method = address_module + '.edit_address';
                    args = {
                        address_name: address_doc.name,
                        values
                    };
                } else {
                    // New address
                    method = address_module + '.add_new_address';
                    args = { doc: values };
                }
                d.hide();
                frappe.call({
                    method: method,
                    args: args,
                    freeze: true
                })
                .then(r => {
                    if (update_cart) {
                        this.update_cart_address(
                            r.message.address_type.toLowerCase(),
                            r.message.name
                        );
                    } else {
                        frappe.freeze()
                        window.location.reload();
                    }
                });
            }
        })

        const country_field = d.get_field('country');
        country_field.$input_area.find('.link-btn').remove()
        country_field.get_query = () => {
            if (d.get_value('address_type') === 'Shipping') {
                return {query: 'ugs_web.custom_methods.cart.shipping_country_query'};
            } else {
                return {query: 'ugs_web.custom_methods.cart.billing_country_query'};
            }
        }

        if (address_doc) {
            // Set values in the dialog
            d.set_values(address_doc);
        }

        d.show();
    },
    update_cart_address: function(address_type, address_name) {
        frappe.call({
            method: cart_module + '.update_cart_address',
            args: {address_type, address_name},
            freeze: true
        }).then((r) => {
            if (r.error) {
                frappe.msgprint({
                    title: "Unable to select address",
                    message: r.error,
                    indicator: "red"
                });
            } else if (!r.exc) {
                this.unfreeze_for_address();
                // Set fieldname addresses
                const fieldname = {
                    'shipping': 'shipping_address_name',
                    'billing': 'customer_name'
                }[address_type];
                const $section = $(`.cart-payment-addresses [data-section="${address_type}"]`);
                let $address_row;
                $section.find('.cart-address-row').each(function() {
                    const $row = $(this);
                    const address = $row.data('address');
                    if (address.address_name === address_name) {
                        $row.attr('data-fieldname', fieldname);
                        $address_row = $row;
                    } else {
                        $row.attr('data-fieldname', null);
                    }
                });
                if (address_name && !$address_row) {
                    // We must add a new card for this new address
                    $new_row = $(r.message.address_row);
                    $section.append($new_row);
                    shopping_cart.bind_select_address(
                        $new_row.find(".cart-select-address")
                    );
                    shopping_cart.bind_edit_address(
                        $new_row.find(".cart-edit-address")
                    );
                    shopping_cart.bind_delete_address(
                        $new_row.find(".cart-delete-address")
                    );
                    // Ensure the 'change' button is now shown
                    $section.find('.btn-change-address').show();
                    // Update the select-address-title
                    $section.find('.select-address-title strong').text(
                        `Select ${address_type} address from list below`
                    );
                }
                // Update the remaining address
                if (address_name) {
                    $section.removeClass('select-mode');
                }
                if ($address_row && $address_row.length) {
                    $address_row.find('.card-title')
                        .text(r.message.address_title);
                    $address_row.find('.card-text')
                        .html(r.message.address_display);
                }
                $(".cart-items").html(r.message.items);
                $(".cart-tax-items").html(r.message.total);
                $(".payment-summary").html(r.message.payment_summary);
                shopping_cart.bind_events();
            }
        });
    },
    freeze_for_address: function(address_type) {
        // Freeze all other areas of the form
        $('.cart-table').parent().addClass('ugs-disable-card');
        $('.frappe-card.payment-summary').addClass('ugs-disable-card');
        $('.cart-payment-addresses [data-section]').addClass('ugs-disable-card');
        if (address_type == 'shipping') {
            $('#input_same_billing').addClass('ugs-disable-card')
                .prop('disabled', true);
        }
    },
    unfreeze_for_address: function() {
        // Unfreeze form
        $('.cart-table').parent().removeClass('ugs-disable-card');
        $('.frappe-card.payment-summary').removeClass('ugs-disable-card');
        $('.cart-payment-addresses [data-section]').removeClass('ugs-disable-card');
        $('#input_same_billing').removeClass('ugs-disable-card')
            .prop('disabled', false);
    }
});
