/*!
ugs_web filter box class
*/

class UGSFilterSidebar {
    constructor() {
        // Setup up filter sidebar
        this.setup_filters()
        $('#ugs-filter-sidebar-apply-button').click(this.apply.bind(this));
        $('#ugs-filter-sidebar-reset-button').click(this.reset.bind(this));
    }

    get_filters() {
        // Get all the filters
        return $('.ugs-filter-sidebar .ugs-filter-field');
    }

    get_child_filters($filter) {
        // Get filters that depend on this filter
        const field_code = $filter.data('filter-field-code');
        return this.get_filters().filter(
            `[data-filter-depends-on-field-code="${field_code}"]`
        );
    }

    reset_filter($filter) {
        // Reset a filter to the default (empty) state
        const filter_type = $filter.data('filter-type');
        const $inputs = $filter.find('input')
        if (filter_type === 'Check') {
            // Set the last radio check True
            $inputs.last().prop('checked', true);
        } else if (filter_type == 'Select') {
            // Unset all checkboxes
            $inputs.filter('[type="checkbox"]').prop('checked', false);
            // Unset any text inputs
            $inputs.filter('[type="text"]').val('')
        } else {
            // Empty all inputs
            $inputs.val('');
        }
    }

    get_filter_value($filter) {
        // Get the value of a filter;
        const filter_type = $filter.data('filter-type');
        const $inputs = $filter.find('input')
        if (filter_type === 'Check' || filter_type === 'Select') {
            // Check for Awesomplete input
            if ($inputs.filter('[type="text"]').length) {
                return $inputs.first().val();
            }
            // If no Awesomplete, check a list of checked values
            const vals = $inputs.filter(':checked').map(function() {
                return $(this).data('filter-value')[0]
            }).get();
            return filter_type === 'Check' ? vals[0] : vals
        } else if (filter_type === 'Data') {
            // Single data field
            return $inputs.val();
        } else {
            // Pair of data fields
            const val_from = $inputs.first().val();
            const val_to = $inputs.last().val();
            if (filter_type == 'Int') {
                return [
                    val_from === '' ? null : parseInt(val_from),
                    val_to === '' ? null : parseInt(val_to)
                ]
            } else if (filter_type == 'Float') {
                return [
                    val_from === '' ? null : parseFloat(val_from),
                    val_to === '' ? null : parseFloat(val_to)
                ]
            } else {
                // Date
                return [val_from || null, val_to || null];
            }
        }
    }

    get_all_filter_values() {
        // Get *all* filter values
        const me = this;
        const values = this.get_filters().map(function () {
            const $filter = $(this);
            return [[$filter.data('filter-field-code'), me.get_filter_value($filter)]];
        }).get();
        return Object.fromEntries(values);
    }

    get_filter_values() {
        // Get currently set filter values
        const me = this;
        const values = this.get_filters().map(function () {
            const $filter = $(this);
            return [[$filter.data('filter-field-code'), me.get_filter_value($filter)]];
        }).get();
        return Object.fromEntries(
            values.filter(([key, val]) => {
                if (val === '') {
                    return false;
                }
                if (Array.isArray(val)) {
                    // Check for empty and [null, null] or ['', ''] arrays
                    if (val.length === 0) {
                        // empty array
                        return false;
                    }
                    if (val.length === 2 && val[0] === null && val[1] === null) {
                        // [null, null]
                        return false;
                    }
                }
                return true;
            })
        );
    }

    validate_range_field($filter) {
        // Validate range field (Int, Float or Date)
        const $inputs = $filter.find('input');
        const value = this.get_filter_value($filter);
        $inputs.removeClass('is-valid');
        $inputs.removeClass('is-invalid');
        if (value[0] || (value[0] === 0)) {
            $inputs.first().addClass('is-valid');
        }
        if (value[1] || (value[1] === 0)) {
            $inputs.last().addClass('is-valid');
        }
        // Validate that 'from' is less than 'to', if both values set
        if (value[0] === null || value[1] === null) {
            return;
        }
        if (value[1] < value[0]) {
            // Validation failed
            $inputs.removeClass('is-valid');
            $inputs.addClass('is-invalid');
        }
    }

    validate_select($filter) {
        // Validate Select fields: check awesomeplete text fields
        // against valid options
        const value = this.get_filter_value($filter);
        if (!value) {
            return;
        }
        const $input = $filter.find('input[type="text"]').first();
        if (!$input.length) {
            return;
        }
        const options = $input.data('options') || {};
        if (!options.hasOwnProperty(value)) {
            // Only allow valid options
            $input.val('');
        }
    }

    validate_filter($filter) {
        const filter_type = $filter.data('filter-type')
        // Validate range fields
        if (['Int', 'Float', 'Date'].includes(filter_type)) {
            this.validate_range_field($filter);
        }
        // Validate awesomeplete text fields against valid options
        if (filter_type === 'Select') {
            this.validate_select($filter);
        }
    }

    handle_input(ev) {
        // Handle a change in this filter
        const $input = $(ev.currentTarget);
        let $filter;
        if ($input.hasClass('ugs-filter-field')) {
            $filter = $input;
        } else {
            $filter = $input.parents('.ugs-filter-field').first();
        }
        const filter_type = $filter.data('filter-type')
        // Validate int and float values in current input
        const input_val = $input.val();
        const range = [null, null];
        if (filter_type === 'Int' || filter_type === 'Float') {
            range[0] = parseFloat($filter.data('filter-min'));
            range[1] = parseFloat($filter.data('filter-max'));
        }
        if (filter_type === 'Int') {
            const val = parseInt(input_val);
            if (!isFinite(val)) {
                $input.val('');
            } else if (!isNaN(range[0]) && input_val < range[0]) {
                $input.val(range[0]);
            } else if (!isNaN(range[1]) && input_val > range[1]) {
                $input.val(range[1]);
            } else if (input_val !== val.toString()) {
                $input.val(val);
            }
        }
        if (filter_type === 'Float') {
            const digits = $filter.data('filter-digits');
            const val = parseFloat(input_val);
            if (!isFinite(val)) {
                $input.val('');
            } else if (!isNaN(range[0]) && input_val < range[0]) {
                $input.val(range[0]);
            } else if (!isNaN(range[1]) && input_val > range[1]) {
                $input.val(range[1]);
            } else if (digits) {
                $input.val(val.toFixed(digits));
            } else if (input_val !== val.toString()) {
                $input.val(val);
            }
        }
        this.validate_filter($filter)
        this.refresh_filter($filter);
    }

    toggle_filter($filter, show) {
        // Show or hide a filter and any filters that depend on it
        const me = this;
        const field_code = $filter.data('filter-field-code');
        const children = this.get_child_filters($filter);
        if (show) {
            $filter.show();
            children.each(function () {
                me.refresh_filter($(this));
            });
        } else {
            this.reset_filter($filter);
            $filter.hide();
            children.each(function () {
                me.toggle_filter($(this), false);
            });
        }
    }

    refresh_filter($filter) {
        // Hide or show filter depending on 'depends_on_field_code' and
        // 'depends_on_options'
        const depends = $filter.data('filter-depends-on-field-code');
        if (!depends) {
            this.toggle_filter($filter, true);
            return;
        }
        // Get the depended on filter
        const $depended_filter = this.get_filters().filter(
            `[data-filter-field-code="${depends}"]`);
        if (!$depended_filter.length) {
            // No depended filter; hide
            this.toggle_filter($filter, false);
            return;
        }
        const depended_type = $depended_filter.data('filter-type');
        let valid_depends;
        const value = this.get_filter_value($depended_filter);
        if (depended_type == 'Check') {
            valid_depends = (value == 'Yes');
        } else if (depended_type === 'Select') {
            const depends_on_options = $filter.data('filter-depends-on-options');
            valid_depends = (
                value.length &&
                value.every(val => depends_on_options.includes(val))
            );
        } else if (depended_type === 'Data') {
            valid_depends = !!value;
        } else {
            valid_depends = (value[0] || value[1]);
        }
        this.toggle_filter($filter, valid_depends);
    }

    setup_filters() {
        // Set up all filters
        const me = this;
        const $filters = this.get_filters();
        // Handle inputs for each filter, and set up
        // any awesomplete filters
        $filters.each(function() {
            const $filter = $(this)
            const $inputs = $filter.find('input');
            // Add awesomplete to select text boxes
            if ($filter.data('filter-type') === 'Select') {
                $inputs.filter('[type="text"]').each(function () {
                    const $input = $(this);
                    const options = $input.data('options');
                    const list = Object.entries(options).map(
                        ([option, count]) => {
                            return {
                                label: `${option} (${count})`,
                                value: option
                            };
                        }
                    );
                    $input.awesomplete = new Awesomplete(this, {
                        list: list,
                        minChars: 0,
                        maxItems: 20
                    });
                });
            }
            // Add 'change' event
            $inputs.on('change', (ev) => {
                me.handle_input(ev);
            });
        });
        // Refresh the display of each filter
        $filters.each(function () {
            const $filter = $(this);
            me.refresh_filter($filter);
            me.validate_filter($filter);
        });
    }

    apply() {
        // Apply new filter settings
        if ($('.ugs-filter-sidebar').find('.is-invalid').length) {
            return;
        }
        const filter_values = this.get_filter_values();
        const uri = URI(window.location.href);
        const queries = uri.query(true);
        // Save sort_order, ppp, search and start from query string
        const saved_queries = (
            ({ sort_order, ppp, search, start }) => ({ sort_order, ppp, search, start })
        )(queries);
        // Add filter query parameters
        Object.keys(filter_values).forEach(key => {
            const val = JSON.stringify(filter_values[key]);
            saved_queries[key] = val;
        });
        // Set new query string
        uri.query(saved_queries);
        window.location.href = uri.normalize();
    }

    reset() {
        // Reset all filters, and run apply
        const me = this;
        const $filters = me.get_filters();
        $filters.each(function () {
            me.reset_filter($(this));
        });
        $filters.each(function () {
            me.refresh_filter($(this));
        });
        this.apply();
    }
};


frappe.ready(function() {

    var ugs_filter_sidebar = new UGSFilterSidebar();
    window.ugs_filter_sidebar = ugs_filter_sidebar;

});
